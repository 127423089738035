import React, {useRef} from "react"
import withLayout from "../../layout"
import {FormattedMessage, injectIntl} from "react-intl"
import HeroCaseStudy from "../../components/HeroCaseStudy"
import SEO from "../../components/SEO"
import PromdLogo from "../../images/case_studies/jago/promd.svg"
import FullWidthMessage from "../../components/CaseStudy/FullWidthMessage"
import DropBg from "../../components/UI/DropBg"
import DropBig1 from '../../images/case_studies/acea/DropBig1.svg'
import DropUp from '../../images/case_studies/audi/DropUp.svg'
import {Fade} from "react-reveal"
import {Heading, Paragraph} from "../../components/UI/Typography"
import Image from "../../components/UI/Image"
import Citation from "../../images/citation.svg"
import VideoJago from "../../images/case_studies/jago/banner_Jago.mp4"
import DropBig2 from "../../images/case_studies/acea/DropTopLeftM.svg"
import DropBottom from "../../images/case_studies/audi/DropBottom.svg"
import VideoJago2 from "../../images/case_studies/jago/Jago_def.mp4"
import LottieController from "../../components/UI/LottieController"
import Graph1 from "../../components/UI/Lottie/Colleghi.json"
import SimpleStats from "../../components/UI/SimpleStats"
import Result2 from "../../components/UI/Lottie/Occhio.json"
import {useInView} from "react-intersection-observer"
import VideoPlayer from "../../components/Hero/VideoPlayer"
import NextSuccessStory from "../../components/CaseStudy/NextSuccessStory"
import SectionTitle from "../../components/CaseStudy/SectionTitle"
import Results from "../../components/CaseStudy/Results"
import {AwwwardsFlag} from "../../images/icons"
import FullWidthImage from "../../components/CaseStudy/FullWidthImage"

const JagoCaseStudy = ({intl}) => {
    const keywords = intl.formatMessage({id: 'jago.meta_keywords'}).split(',') || []
    const [graphView1, inViewG1] = useInView()
    const [graphView2, inViewG2] = useInView()
    const videoRef = useRef()

    return <>
        <SEO
            title={intl.formatMessage({id: 'jago.meta_title'})}
            description={intl.formatMessage({id: 'jago.meta_description'})}
            keywords={keywords}
            lang={intl.locale}
        />
        <HeroCaseStudy
            heroImage={'case_studies/jago/Header-Jago.png'}
            title={'jago.title'}
            intl={intl}
            subtitle1={'jago.subtitle'}
            hasPartner={true}
            partnerLogo={PromdLogo}
            color={'linear-gradient(90deg, #000000 0%, #515151 100%)'}
            categories={['portfolio.filters.design_dev', 'portfolio.filters.creative_communication']}
        />
        <div className="section-padding">
            <DropBg
                src={DropBig1}
                left={'0%'}
                zIndex={'-1'}
                bottom={'-20%'}
                className="d-none d-lg-block"
            />
            <DropBg
                src={DropUp}
                bottom={'5%'}
                left={'25%'}
                className="d-none d-lg-block"
            />
            <div className="container">
                <div className="row">
                    <Fade bottom delay={200}>
                        <div className="col-12">
                            <SectionTitle
                                number={'01'}
                                text={'jago.overview_title'}
                                color={'#000'}
                            />
                        </div>
                    </Fade>
                </div>
                <div className="row">
                    <div className={"col-md-6 col-12"}>
                        <Fade bottom delay={200}>
                            <Heading level={"3"}>
                                <FormattedMessage id={"jago.client_title"}/>
                            </Heading>
                        </Fade>
                        <Fade bottom delay={400}>
                            <Paragraph className="pt-3">
                                <FormattedMessage id={"jago.client_text_1"}/>
                            </Paragraph>
                            <Paragraph className="pt-3">
                                <FormattedMessage id={"jago.client_text_2"}/>
                            </Paragraph>
                        </Fade>
                    </div>
                    <div className={"col-md-5 col-12 offset-md-1"}>
                        <Fade bottom delay={600}>
                            <Heading level={"4"} className="pt-2">
                                <FormattedMessage id={"jago.client_needs_title"}/>
                            </Heading>
                        </Fade>
                        <Fade bottom delay={800}>
                            <Paragraph className="pt-3">
                                <FormattedMessage id={"jago.client_needs_text"}/>
                            </Paragraph>
                        </Fade>
                    </div>
                </div>
                <div className="row">
                    <Fade bottom delay={500}>
                        <div className={"col-md-6 col-12"}>
                            <Image
                                filename={'jago/jago1.jpg'}
                                alt={'Jago'}
                            />
                        </div>
                    </Fade>
                    <Fade bottom delay={900}>
                        <div className={"col-md-5 col-12 offset-md-1"}>
                            <div className="testimonial-wrapper">
                                <img
                                    src={Citation}
                                    alt={'citation'}
                                    style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0
                                        }}
                                />
                                <img
                                    src={Citation}
                                    alt={'citation'}
                                    style={{
                                            position: 'absolute',
                                            bottom: 0,
                                            right: 0,
                                            transform: 'rotate(180deg)'
                                        }}
                                />
                                <p className="sentence">
                                    <FormattedMessage id={"jago.citation"}/>
                                </p>
                                <div className="text-center">
                                    <Paragraph
                                        customColor={'blueHoki'}
                                        customSize={'14'}
                                        bold={true}
                                    >
                                        <FormattedMessage id={"jago.citation_user"}/>
                                    </Paragraph>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
        <FullWidthImage
            dangerouslySetInnerHTML={{
                __html: `
                    <video
                        style="width:100%;height:100%;object-fit:cover"
                        loop
                        muted
                        autoplay
                        playsinline
                        webkit-playsinlines
                        src="${VideoJago}"
                    />
                `
            }}
            imageWidth={1440}
            imageHeight={702}
        />
        <div className="section-padding">
            <DropBg
                src={DropBig2}
                right={'-10%'}
                zIndex={'-1'}
                top={'15%'}
                className="d-none d-lg-block"
            />
            <DropBg
                src={DropBottom}
                top={'10%'}
                right={'10%'}
                className="d-none d-lg-block"
            />
            <Fade bottom delay={400}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-2 d-none d-md-block">
                            <SectionTitle
                                number={'02'}
                                text={'jago.brief_title'}
                                color={'#000'}
                            />
                        </div>
                        <div className="col-md-8 col-xs-12">
                            <Heading
                                level={"2"}
                                margin={'0px'}
                            >
                                <FormattedMessage id={"jago.service_big_title"}/>
                            </Heading>
                            <Paragraph className="pt-2">
                                <FormattedMessage id="jago.services_txt_1"/>
                            </Paragraph>
                        </div>
                    </div>
                </div>
            </Fade>
        </div>
        <div className="d-flex align-items-center">
            <div className="container">
                <div className="row">
                    <Fade bottom delay={400}>
                        <div className="col-12">
                            <SectionTitle
                                number={'03'}
                                text={'jago.workflow_title'}
                                color={'#000'}
                            />
                        </div>
                    </Fade>
                </div>
                <div className="row">
                    <Fade bottom delay={800}>
                        <div className="col-md-7 col-sm-12">
                            <Heading level={"3"}>
                                <FormattedMessage id={"jago.solutions_big_title"}/>
                            </Heading>
                            <Heading level={"4"}>
                                <FormattedMessage id={"jago.solutions_title_1"}/>
                            </Heading>
                            <Paragraph>
                                <FormattedMessage id={"jago.solutions_txt_1"}/>
                            </Paragraph>
                            <Paragraph className="pt-3">
                                <FormattedMessage id={"jago.solutions_txt_2"}/>
                            </Paragraph>
                        </div>
                    </Fade>
                    <Fade bottom delay={1000}>
                        <div className="col-md-5 col-sm-12 position-relative d-flex justify-content-center align-items-center">
                            <Image
                                filename={'case_studies/jago/map.jpg'}
                                alt={'Jago map'}
                            />
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
        <div className="section-padding-top">
            <Fade bottom delay={400}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <Heading level={"4"}>
                                <FormattedMessage id={"jago.solutions_title_2"}/>
                            </Heading>
                            <Paragraph>
                                <FormattedMessage id={"jago.solutions_txt_2_2"}/>
                            </Paragraph>
                        </div>
                    </div>
                </div>
            </Fade>
        </div>
        <div className={"container"}>
            <div className={"row"}>
                <Fade bottom delay={400}>
                    <div className={"col-12"}>
                        <video
                            loop
                            muted
                            autoPlay
                            playsInline
                            webkit-playsinlines
                            src={VideoJago2}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                            }}
                        />
                    </div>
                </Fade>
            </div>
        </div>
        <div className="section-padding-bottom">
            <div className="container">
                <div className="row">
                    <Fade bottom delay={400}>
                        <div className="col-lg-6 col-md-12 col-12">
                            <Heading level={"4"}>
                                <FormattedMessage id={"jago.video_production_title"}/>
                            </Heading>
                            <Paragraph className="pt-2">
                                <FormattedMessage id={"jago.video_production_txt_1"}/>
                            </Paragraph>
                        </div>
                    </Fade>
                    <Fade bottom delay={600}>
                        <div className="col-lg-6 col-md-12 col-12 align-items-center justify-content-center">
                            <div className="case-study-partner d-flex align-items-center justify-content-end">
                                <p>
                                    <FormattedMessage id={'enel.collaboration'}/>
                                </p>
                                <img
                                    src={PromdLogo}
                                    alt='logo Promd'
                                />
                            </div>
                            <Paragraph className="pt-2">
                                <FormattedMessage id={"jago.video_production_txt_2"}/>
                            </Paragraph>
                        </div>
                    </Fade>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Fade bottom delay={800}>
                            <VideoPlayer
                                hasOverlay={false}
                                vimeoId="553393345"
                                title="Jago video"
                                className="pt-4"
                                allowFullscreen={false}
                                ref={videoRef}
                            />
                        </Fade>
                        <Fade bottom delay={1000}>
                            <Paragraph className="py-4">
                                <FormattedMessage id={"jago.video_production_txt_3"}/>
                            </Paragraph>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
        <Results
            subtitle="jago.dark_section_paragraph"
            testimonialQuote="jago.dark_section_quote"
            testimonialImg="sara_mastrantonio.jpg"
            testimonialName="jago.dark_section_testimonial_name"
            testimonialRole="jago.dark_section_testimonial_desc"
        >
            <div className="col-12 col-lg-3">
                <div ref={graphView1}>
                    <LottieController
                        lottieJSON={Graph1}
                        inView={inViewG1}
                        height="100px"
                        width="122px"
                        loop={false}
                    />
                    <SimpleStats
                        labelWidth="250px"
                        inView={inViewG1}
                        valueFrom={0}
                        valueTo={3}
                        prefix="+"
                        measureUnit="K"
                        headingStyle={{fontWeight: 500}}
                        totalDuration={0.5}
                        label="jago.stats_1"
                        headingLevel="1"
                        labelColor="white"
                    />
                </div>
            </div>
            <div className="col-12 col-lg-3">
                <div ref={graphView2}>
                    <LottieController
                        lottieJSON={Result2}
                        inView={inViewG2}
                        height="98px"
                        width="86px"
                        loop={false}
                    />
                    <SimpleStats
                        labelWidth="250px"
                        measureUnit="k"
                        inView={inViewG2}
                        valueFrom={0}
                        valueTo={30}
                        label="jago.stats_2"
                        headingLevel="1"
                        labelColor="white"
                        headingStyle={{fontWeight: 500}}
                    />
                </div>
            </div>
        </Results>
        <FullWidthMessage isBig intl={intl}/>
        <NextSuccessStory
            title="insieme.next_success_case_title"
            desc="insieme.next_success_case_description"
            link="/case-study/mantegna/"
            intl={intl}
        />

        <div
            id="awwwards"
            style={{
                    zIndex: 900,
                    position: "fixed",
                    top: "50%",
                    right: 0,
                    transform: "translateY(-50%)"
                }}
        >
            <a
                href="https://www.awwwards.com/sites/jago"
                target="_blank"
            >
                <AwwwardsFlag/>
            </a>
        </div>
    </>
}

const customProps = {
    localeKey: "jago", // same as file name in src/i18n/translations/your-lang/index.js
}

export default withLayout(customProps)(injectIntl(JagoCaseStudy))
