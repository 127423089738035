import React, {forwardRef, useEffect, useState} from "react"
import styled from "styled-components"

const Plyr = React.lazy(() =>
    import("plyr-react")
)

const Overlay = styled.div`
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:109;
    background-color:rgba(0,0,0,0.5);
    display:flex;
    justify-content:center;
    align-items:center;
`

const VideoPlayer = forwardRef(({
                                    hasOverlay = true,
                                    closeCb,
                                    vimeoId,
                                    title,
                                    autoplay = false,
                                    allowFullscreen = true,
                                    ...rest
                                }, ref) => {
    const [plyrProps, setPlyrProps] = useState({})
    const isSSR = typeof window === "undefined"

    useEffect(() => {
        setPlyrProps({
            source: {
                type: "video",
                title,
                sources: [
                    {
                        src: vimeoId,
                        provider: "vimeo",
                    }
                ]
            },
            options: {
                controls: [
                    "play-large",
                    "play",
                    "progress",
                    "current-time",
                    "mute",
                    "volume",
                    allowFullscreen && "fullscreen"
                ],
                autoplay,
                resetOnEnd: true,
                fullscreen: {
                    enabled: allowFullscreen,
                    iosNative: true
                }
            }
        })

        if (hasOverlay) {
            const htmlTag = window?.document?.querySelector("html")
            htmlTag.style.overflowY = "hidden"

            return () => htmlTag.style.overflowY = "auto"
        }
    }, [])


    return (
        <>
            {hasOverlay && <Overlay onClick={closeCb}/>}
            <div {...rest}>
                {!isSSR && (
                    <React.Suspense fallback={<div/>}>
                        <Plyr {...plyrProps}/>
                    </React.Suspense>
                )}
            </div>
        </>
    )
})

export default VideoPlayer
